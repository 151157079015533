import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

// third-party
import OtpInput from 'react-otp-input-rc-17';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { openSnackbar } from 'store/slices/snackbar';
import { useEffect } from 'react';

// ============================|| STATIC - CODE VERIFICATION ||============================ //

const AuthCodeVerification = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState();
    const { verifyOtp,otpVerificationSuccess,resetPassword } = useAuth();
    const [error, setError] = useState(false);
    const [timer, setTimer] = useState(60);
    const [expired, setExpired] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const borderColor = theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300];
    const email=localStorage.getItem('forget-mail');
    let interval;
    useEffect(()=>{
     if(!expired)
     {
        interval=setInterval(function () {
            setTimer((prev)=>prev-1);
        }, 1000);
        
     }
    },[expired])
    useEffect(()=>{
      if(timer<1)
      {
        setExpired(true);
        clearInterval(interval);
      }
    },[timer])
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
            {(error)&&<Typography sx={{color:'red',textAlign:'center'}}>{message}</Typography>}
                <OtpInput
                    value={otp}
                    onChange={(otpNumber) => setOtp(otpNumber)}
                    numInputs={6}
                    containerStyle={{ justifyContent: 'space-between' }}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid ${borderColor}`,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: theme.palette.primary.main
                        }
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid ${theme.palette.primary.main}`
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button 
                 disableElevation 
                 fullWidth 
                 size="large" 
                 type="button" 
                 variant="contained"
                 onClick={async ()=>{
                    try {
                            let response=await verifyOtp({email,
                                                            otp});
                            if(response.data.status)
                            {
                                otpVerificationSuccess(response.data);
                                setTimeout(() => {
                                    navigate('/forget/change-password', { replace: true });
                                }, 1500);
                                
                            }else{
                                setError(true);
                                setMessage(response.data.message)
                            }
                        } catch (err) {
                                setError(true);
                                setMessage( err.message);
                        }
                }}>
                    Continue
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                    <Typography>Did not receive the email? Check your spam filter, or</Typography>
                    <Typography variant="body1" sx={{ minWidth: 85, ml: 2, textDecoration: 'none', cursor: 'pointer' }} color="primary" onClick={async()=>{
                       if(expired){
                       try {
                            let response=await resetPassword(email);
                            if(response.data.status)
                            {
                             
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            message: 'Email send successfully',
                                            variant: 'alert',
                                            alert: {
                                                color: 'success'
                                            },
                                            close: false
                                        })
                                    );
                                    setExpired(false);
                                    setTimer(60);
                            }else{
                                setError(true);
                                setMessage( response.data.message);
                            }
                        } catch (err) {
                                setError(true);
                                setMessage( err.message);
                        }
                    }
                    }}>
                       {expired?<>Resend code</>:<>{timer}</>} 
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};
export default AuthCodeVerification;
