// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { openSnackbar } from 'store/slices/snackbar';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCRRuQJMlQ37wqkziBHAT-3i5ijIrSv55A',
    authDomain: 'a-task-25ed1.firebaseapp.com',
    projectId: 'a-task-25ed1',
    storageBucket: 'a-task-25ed1.appspot.com',
    messagingSenderId: '244734210110',
    appId: '1:244734210110:web:247a3f09b4f17c6ddf616f',
    measurementId: 'G-CE2N8TK5YT'
};

// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BL64k9wKqwRj5RqyUnCI4mCEqEd5cn-zPkQROl0oq9MJs_3-Y4iO7svk0jUGTY1D2XbkT-1Zf-aq5tSyqbYM8is' })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                return currentToken;
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            openSnackbar({
                open: true,
                message: `${payload.notification.title}: ${payload.notification.body}`,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            });
            resolve(payload);
        });
    });
