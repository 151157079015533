// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { requestForToken, onMessageListener } from './firebase';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    //     const [notification, setNotification] = useState({ title: '', body: '' });
    //     function ToastDisplay() {
    //     return (
    //       <div>
    //         <p><b>{notification?.title}</b></p>
    //         <p>{notification?.body}</p>
    //       </div>
    //     );
    //   };
    //     const notify = () => toast(<ToastDisplay />);
    //     useEffect(() => {
    //         requestForToken();
    //         onMessageListener()
    //             .then((payload) => {
    //                 console.log(payload);
    //                 // setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    //             })
    //             .catch((err) => console.log('failed: ', err));
    //     }, []);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
