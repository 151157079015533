import { Grid, Paper, Typography } from '@mui/material';
import { IconAlarm } from '@tabler/icons';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getNotification } from 'store/slices/notification';
import { requestForToken, onMessageListener } from './firebase';

const Notification = () => {
    const [notification, setNotification] = useState({ title: '', body: '' });
    const dispatch = useDispatch();
    const notify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        return (
           
            <Grid container spacing={2}>
                <Grid item xs={3}>
                <IconAlarm stroke={1.5} size="30px" style={{color:'green'}}/>
                </Grid>
                <Grid item xs={9} ><Typography variant='h4'>{notification?.title}</Typography><Typography sx={{color:'#9e9e9e'}}>{notification?.body}</Typography></Grid>
                </Grid>
        );
    }

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    // requestForToken();

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
            dispatch(getNotification());
        })
        .catch((err) => console.log('failed: ', err));

    return <Toaster position="top-right" />;
};

export default Notification;
