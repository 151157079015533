// third-party
import { createSlice } from '@reduxjs/toolkit';
import { func } from 'prop-types';
import { updateShorthandPropertyAssignment } from 'typescript';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    columns: [],
    columnsOrder: [],
    comments: [],
    items: [],
    projectTasks: [],
    profiles: [],
    selectedItem: false,
    userStory: [],
    userStoryOrder: [],
    addTaskResponse: null,
    fileUploadResponse: null,
    boardTaskStatuses: [],
    taskList: []
};

const slice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // ADD COLUMN
        addColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // EDIT COLUMN
        editColumnSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // UPDATE COLUMN ORDER
        updateColumnOrderSuccess(state, action) {
            state.columnsOrder = action.payload.columnsOrder;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // ADD ITEM
        addItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // EDIT ITEM
        editItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // UPDATE COLUMN ITEM ORDER
        updateColumnItemOrderSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // SELECT ITEM
        selectItemSuccess(state, action) {
            state.selectedItem = action.payload.data;
        },

        // ADD ITEM COMMENT
        addItemCommentSuccess(state, action) {
            state.items = action.payload.items;
            state.comments = action.payload.comments;
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // ADD TASK
        addTaskSuccess(state, action) {
            const { data } = action.payload;
            state.addTaskResponse = data;
        },

        // EDIT STORY
        editStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // UPDATE STORY ORDER
        updateStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // UPDATE STORY ITEM ORDER
        updateStoryItemOrderSuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // ADD STORY COMMENT
        addStoryCommentSuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.comments = action.payload.comments;
        },

        // DELETE STORY
        deleteStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // GET COLUMNS
        getColumnsSuccess(state, action) {
            state.columns = action.payload;
        },

        // GET COLUMNS ORDER
        getColumnsOrderSuccess(state, action) {
            state.columnsOrder = action.payload;
        },

        // GET COMMENTS
        getCommentsSuccess(state, action) {
            state.comments = action.payload;
        },

        // GET PROFILES
        getProfilesSuccess(state, action) {
            state.profiles = action.payload;
        },

        // GET ITEMS
        getItemsSuccess(state, action) {
            const { status, message, data } = action.payload;
            if (status == true) {
                // state.projectTasks = action.payload;
                // state.items = data.task;
                // state.projectTasks = data.project_task;

                let reordered_tasks = [];
                data.task.forEach((task) => {
                    if (task.task_status != 3) {
                        reordered_tasks.push(task);
                    }
                });
                data.task.forEach((task) => {
                    if (task.task_status == 3) {
                        reordered_tasks.push(task);
                    }
                });

                state.items = reordered_tasks;

                let projectTask = data.project_task.map((project) => {
                    let reordered_tasks_project = [];
                    project.task.forEach((task) => {
                        if (task.task_status != 3) {
                            reordered_tasks_project.push(task);
                        }
                    });
                    project.task.forEach((task) => {
                        if (task.task_status == 3) {
                            reordered_tasks_project.push(task);
                        }
                    });
                    return { ...project, task: reordered_tasks_project };
                });

                let allTasks = [...data.task];
                data.project_task.forEach((project) => (allTasks = [...allTasks, ...project.task]));
                state.taskList = allTasks;

                state.projectTasks = projectTask;
            }
        },

        // GET USER STORY
        getUserStorySuccess(state, action) {
            state.userStory = action.payload;
        },

        // GET USER STORY ORDER
        getUserStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload;
        },
        // GET ITEMS
        getBordTaskStatusSuccess(state, action) {
            const { status, message, data } = action.payload;
            if (status == true) {
                state.boardTaskStatuses = data.task_statuses.map((task_status, index) => {
                    let tasks = state.items.filter((item) => item.task_status == task_status.id);
                    state.projectTasks.forEach((projectitem) => {
                        let ptasks = projectitem.task.filter((item) => item.task_status == task_status.id);
                        tasks = [...tasks, ...ptasks];
                    });
                    task_status.tasks = tasks;
                    return task_status;
                });
            }
        },
        reOrderTaskBoard(state, action) {
            const { source, destination } = action.payload;
            state.boardTaskStatuses[source.statusindex].tasks[source.taskIndex] = source.task;
            state.boardTaskStatuses[destination.statusindex].tasks[destination.taskIndex] = destination.task;
        },
        moveTaskBoard(state, action) {
            const { source, destination } = action.payload;
            state.boardTaskStatuses[source.statusindex].tasks = source.tasks;
            state.boardTaskStatuses[destination.statusindex].tasks = destination.tasks;

            // state.boardTaskStatuses[destination.statusindex] = destinationTasks;
        },
        addTaskBoard(state, action) {
            const { destinationTaskStatusId, task, sourceTaskStatusId } = action.payload;
            let destinationStatusindex = state.boardTaskStatuses.findIndex((status) => status.id == destinationTaskStatusId);
            state.boardTaskStatuses[destinationStatusindex].tasks = [task];
            let sourceStatusindex = state.boardTaskStatuses.findIndex((status) => status.id == sourceTaskStatusId);
            state.boardTaskStatuses[sourceStatusindex].tasks = state.boardTaskStatuses[sourceStatusindex].tasks.filter(
                (item) => item.id != task.id
            );
        },
        reOrderPersonalTask(state, action) {
            const { source, destination } = action.payload;
            state.items[source.index] = source.task;
            state.items[destination.index] = destination.task;
        },
        reOrderProjectTask(state, action) {
            const { source, destination, projectIndex } = action.payload;
            state.projectTasks[projectIndex].task[source.index] = source.task;
            state.projectTasks[projectIndex].task[destination.index] = destination.task;
        },
        updateTaskCheckList(state, action) {
            const { project_id, task_id, check_lists } = action.payload;
            if (project_id > 0) {
                let project = [...state.projectTasks].find((project) => project.id == project_id);
                let task = [...project.task].find((task) => task.id == task_id);
                task = { ...task, check_lists: check_lists };
                let taskIndex = [...project.task].findIndex((task) => task.id == task_id);
                let projectIndex = [...state.projectTasks].findIndex((project) => project.id == project_id);
                state.projectTasks[projectIndex].task[taskIndex] = task;
            } else {
                let task = [...state.items].find((task) => task.id == task_id);
                task = { ...task, check_lists: check_lists };
                let taskIndex = [...state.items].findIndex((task) => task.id == task_id);
                state.items[taskIndex] = task;
            }
        },
        updateSubtask(state, action) {
            const { project_id, task_id, child } = action.payload;
            if (project_id > 0) {
                let project = [...state.projectTasks].find((project) => project.id == project_id);
                let task = [...project.task].find((task) => task.id == task_id);
                task = { ...task, child: child };
                let taskIndex = [...project.task].findIndex((task) => task.id == task_id);
                let projectIndex = [...state.projectTasks].findIndex((project) => project.id == project_id);
                state.projectTasks[projectIndex].task[taskIndex] = task;
            } else {
                let task = [...state.items].find((task) => task.id == task_id);
                task = { ...task, child: child };
                let taskIndex = [...state.items].findIndex((task) => task.id == task_id);
                state.items[taskIndex] = task;
            }
        },
        addFile(state, action) {
            const { project_id, task_id, file } = action.payload;
            if (project_id > 0) {
                let project = [...state.projectTasks].find((project) => project.id == project_id);
                let task = [...project.task].find((task) => task.id == task_id);
                task = { ...task, task_file: [...task.task_file,file] };
                let taskIndex = [...project.task].findIndex((task) => task.id == task_id);
                let projectIndex = [...state.projectTasks].findIndex((project) => project.id == project_id);
                state.projectTasks[projectIndex].task[taskIndex] = task;
            } else {
                let task = [...state.items].find((task) => task.id == task_id);
                task = { ...task, task_file: [...task.task_file,file] };
                let taskIndex = [...state.items].findIndex((task) => task.id == task_id);
                state.items[taskIndex] = task;
            }
        },
        updateSnagList(state, action) {
            const { project_id, task_id, snag_lists } = action.payload;
            if (project_id > 0) {
                let project = [...state.projectTasks].find((project) => project.id == project_id);
                let task = [...project.task].find((task) => task.id == task_id);
                task = { ...task, snag_lists: snag_lists };
                let taskIndex = [...project.task].findIndex((task) => task.id == task_id);
                let projectIndex = [...state.projectTasks].findIndex((project) => project.id == project_id);
                state.projectTasks[projectIndex].task[taskIndex] = task;
            }
        },
        updateSingleTask(state, action) {
            const { project_id, task_id, task } = action.payload;
            if (project_id > 0) {
                let project = [...state.projectTasks].find((project) => project.id == project_id);
                let taskIndex = [...project.task].findIndex((task) => task.id == task_id);
                let projectIndex = [...state.projectTasks].findIndex((project) => project.id == project_id);
                state.projectTasks[projectIndex].task[taskIndex] = task;
            } else {
                let taskIndex = [...state.items].findIndex((task) => task.id == task_id);
                state.items[taskIndex] = task;
            }
        },
        updateSort(state, action) {
            const { items, projectTasks } = action.payload;
            state.items = items;
            state.projectTasks = projectTasks;
        }
    }
});

// Reducer
export default slice.reducer;

export const {
    reOrderTaskBoard,
    moveTaskBoard,
    addTaskBoard,
    reOrderPersonalTask,
    reOrderProjectTask,
    updateTaskCheckList,
    updateSubtask,
    addFile,
    updateSnagList,
    updateSingleTask,
    updateSort
} = slice.actions;
// ----------------------------------------------------------------------

export function getColumns() {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/columns');
            dispatch(slice.actions.getColumnsSuccess(response.data.columns));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getItems(search = '') {
    return async () => {
        try {
            const response = await axios.post('/list/task', { search });
            dispatch(slice.actions.getItemsSuccess(response.data));
            dispatch(getBordTaskStatus());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnOrder(data) {
    return async () => {
        try {
            const response = await axios.post('task/update_order', data);
            dispatch(getItems());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function selectItem(id) {
    return async () => {
        try {
            const response = await axios.post('/task/details', { id });
            dispatch(slice.actions.selectItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function taskFileUpload(formData) {
    return async () => {
        return await axios.post('/task/upload/file', formData);
    };
}

export function taskFileUpdate(formData) {
    return async () => {
        return await axios.post('/task/update/file', formData);
    };
}

export function taskFileDelete(ids) {
    return async () => {
        try {
            const response = await axios.post('/task/file/delete', { id: ids });
            // dispatch(slice.actions.fileDeleteSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function commentUpdate(data) {
    return async () => {
        return await axios.post('task/comment/update', data);
    };
}

export function addStory(taskData) {
    return async () => {
        return await axios.post('/create/task', taskData);
    };
}
export function updateStory(taskData) {
    return async () => {
        return await axios.post('/update/task', taskData);
    };
}

export function deleteStory(id) {
    return async () => {
        return await axios.post('/multiple/task/delete', { id });
    };
}

export function createTaskStatus(status, color) {
    return async () => {
        return await axios.post('createTaskStatus', { name: status, color: color, is_last: 0, project: '' });
    };
}

export function changeTaskStatus(id, status) {
    return async () => {
        return await axios.post('/multiple/task/status', { id: id, status: status });
    };
}

export function removeTaskStatus(id) {
    return async () => {
        return await axios.post('deleteTaskStatus', { id: id });
    };
}

export function updateTaskStatusName(id, name, color) {
    return async () => {
        return await axios.post('updateTaskStatus', { id: id, name: name, color: color });
    };
}

export function updateAssignee(assigned_to, id) {
    return async () => {
        try {
            const response = await axios.post('update/task', { assigned_to, id });
            dispatch(getItems());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteCheckList(id) {
    return async () => {
        return await axios.post('deleteChecklist', { id });
    };
}

export function addCheckList(data) {
    return async () => {
        return await axios.post('addCheckList', data);
    };
}

export function updateChecKList(data) {
    return async () => {
        return await axios.post('updateChecklist', data);
    };
}

export function getCheckLists(data) {
    return async () => {
        return await axios.post('listChecklist', data);
    };
}
export function getSubTask(data) {
    return async () => {
        return await axios.post('listSubTask', data);
    };
}
export function getBordTaskStatus() {
    return async () => {
        try {
            const response = await axios.post('/board/task');
            dispatch(slice.actions.getBordTaskStatusSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addSnagList(data) {
    return async () => {
        return await axios.post('task/add/snag_list', data);
    };
}
export function updateSnagListData(data) {
    return async () => {
        return await axios.post('task/update/snag_list', data);
    };
}
export function deleteSnagList(data) {
    return async () => {
        return await axios.post('task/delete/snag_list', data);
    };
}
export function getSnagList(data) {
    return async () => {
        return await axios.post('task/list/snag_list', data);
    };
}
export function snaglistFileDelete(data) {
    return async () => {
        return await axios.post('snag_list/delete/attachment', data);
    };
}
export function updateSnagStatus(data) {
    return async () => {
        return await axios.post('snag_list/update_status', data);
    };
}
export function getTask(id) {
    return async () => {
        return await axios.post('/task/details', { id });
    };
}
export function uploadSnagListFile(data) {
    return async () => {
        return await axios.post('add_attachment/snaglist', data);
    };
}
export function addComment(data) {
    return async () => {
        return await axios.post('/task/comment/add', data);
    };
}
export function deleteCommentAttachment(data) {
    return async () => {
        return await axios.post('/task/comment/file/delete', data);
    };
}
