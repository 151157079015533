import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import EmailVerification from 'views/pages/authentication/EmailVerification3';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));

const ForgetPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));

const CodeVerification = Loadable(lazy(() => import('views/pages/authentication/CodeVerification3')));

const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <ForgetPassword />
        },
        {
            path: '/account/forgot-password',
            element: <ForgetPassword />
        },
        {
            path: '/account/reset-password',
            element: <CodeVerification />
        },
        {
            path: '/account/verify_email',
            element: <EmailVerification />
        },
        {
            path: '/forget/change-password',
            element: <ResetPassword />
        }
    ]
};

export default LoginRoutes;
