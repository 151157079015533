import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
// const AllTasks = Loadable(lazy(() => import('views/all-tasks')));
const AllTasks = Loadable(lazy(() => import('views/all-tasks/Backlogs')));
const Board = Loadable(lazy(() => import('views/all-tasks/Board')));

const Company = Loadable(lazy(() => import('views/company')));
const Department = Loadable(lazy(() => import('views/department')));
const Designation = Loadable(lazy(() => import('views/designation')));
const Projects = Loadable(lazy(() => import('views/projects')));
const Employee = Loadable(lazy(() => import('views/employee')));
const ManageDepartment = Loadable(lazy(() => import('views/manage-department')));
const MyTasks = Loadable(lazy(() => import('views/my-tasks/Backlogs')));
const Profile = Loadable(lazy(() => import('views/user-profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/',
        //     element: <AllTasks />
        // },
        {
            path: '/all-tasks',
            element: <AllTasks />
        },
        {
            path: '/company',
            element: <Company />
        },
        {
            path: '/department',
            element: <Department />
        },
        {
            path: '/designation',
            element: <Designation />
        },
        {
            path: '/project',
            element: <Projects />
        },
        {
            path: '/employee',
            element: <Employee />
        },
        {
            path: '/manage-department',
            element: <ManageDepartment />
        },
        {
            path: '/my-tasks',
            element: <MyTasks />
        },
        {
            path: '/user/profile',
            element: <Profile />
        }
    ]
};

export default MainRoutes;
