// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
// assets
import { IconAlarm } from '@tabler/icons';
import { useSelector } from 'store';
import { useDispatch } from 'react-redux';
import { getNotification, markAsRead } from 'store/slices/notification';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const notificationSelector = useSelector((state) => state.notification);
    const { NotificationList } = notificationSelector;
    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };
    console.log('NotificationList', NotificationList);
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {NotificationList.map((item) => {
                return (
                    <div key={NotificationList.id}>
                        <ListItemWrapper
                            onClick={() => {
                                dispatch(markAsRead(item.id)).then((response) => {
                                    if (response.data.status) {
                                        dispatch(getNotification());
                                    }
                                });
                            }}
                        >
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.success.dark,
                                            backgroundColor:
                                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                                            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                            borderColor: theme.palette.success.main
                                        }}
                                    >
                                        <IconAlarm stroke={1.5} size="20px" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1">
                                            {item.task_id > 0 ? (
                                                <Link
                                                    onClick={() => {
                                                        dispatch(markAsRead(item.id)).then((response) => {
                                                            if (response.data.status) {
                                                                window.location = `all-tasks?task_id=${item.task_id}`;
                                                            }
                                                        });
                                                    }}
                                                >
                                                    {item.title}
                                                </Link>
                                            ) : (
                                                item.title
                                            )}
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {item.total_duration}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ minWidth: '230px' }}>
                                        {item.body}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!item.readed_time && (
                                            <Grid item>
                                                <Chip label="Unread" sx={chipErrorSX} />
                                            </Grid>
                                        )}
                                        {item.status == 0 && (
                                            <Grid item>
                                                <Chip label="New" sx={chipWarningSX} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </div>
                );
            })}
        </List>
    );
};

export default NotificationList;
