// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    projectList: [],
    selectedProject: null,
    selectedVarient: null,
    selectedStakeHolder: null,
    selectedAssignedEmployee: null,
    selectedZone: null,
    selectedFloor: null,
    message: null,
    status: false
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getProject(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.projectList = action.payload.data;
            } else {
                state.projectList = [];
            }
        },
        // getProjectItem(state, action) {
        //     if (action.payload.status) {
        //         let itemIndex = state.projectList.findIndex((item) => item.id == action.payload.data.id);
        //         state.projectList[itemIndex] = action.payload.data;
        //     }
        // },

        // ADD ADDRESS
        addProject(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        deleteProject(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        // EDIT ADDRESS
        updateProject(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },
        setSelectedProject(state, action) {
            state.selectedProject = action.payload;
        },
        setSelectedVarient(state, action) {
            state.selectedVarient = action.payload;
        },
        setSelectedStakeHolder(state, action) {
            state.selectedStakeHolder = action.payload;
        },
        setSelectedAssignedEmployee(state, action) {
            state.selectedAssignedEmployee = action.payload;
        },
        setSelectedZone(state, action) {
            state.selectedZone = action.payload;
        },
        setSelectedFloor(state, action) {
            state.selectedFloor = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const {
    setSelectedProject,
    setSelectedVarient,
    setSelectedStakeHolder,
    setSelectedAssignedEmployee,
    setSelectedZone,
    setSelectedFloor
} = slice.actions;
// ----------------------------------------------------------------------

export function getProjects() {
    return async () => {
        try {
            const response = await axios.post('/projectList');
            dispatch(slice.actions.getProject(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getProject(id) {
    return async () => {
        return await axios.post('/projectItem', { id });
    };
}

export function addProject(companyData) {
    return async () => {
        return await axios.post('/create/project', companyData);
    };
}

export function updateProject(data) {
    return async () => {
        return await axios.post('/update/project', data);
    };
}

export function deleteProject(data) {
    return async () => {
        return await axios.post('/project/delete', data);
    };
}

export function deleteAttachment(data) {
    return async () => {
        return await axios.post('/project/attachment/delete', data);
    };
}
// export function deleteFloor(data) {
//     return async () => {
//         return await axios.post('/project/floor/delete', data);
//     };
// }
export function addVariant(data) {
    return async () => {
        return await axios.post('/create/variant/project', data);
    };
}
export function updateVariant(data) {
    return async () => {
        return await axios.post('/update/variant/project', data);
    };
}
export function getVariant(data) {
    return async () => {
        return await axios.post('/details/variant/project', data);
    };
}
export function addAttachmentVariant(data) {
    return async () => {
        return await axios.post('/add_attachment/variant/project', data);
    };
}
export function updateAttachmentVariant(data) {
    return async () => {
        return await axios.post('/update_attachment/variant/project', data);
    };
}
export function deleteAttachmentVariant(data) {
    return async () => {
        return await axios.post('/project/attachment/delete', data);
    };
}
export function addStakeHolder(data) {
    return async () => {
        return await axios.post('/create_stake_holder/variant/project', data);
    };
}
export function updateStakeHolder(data) {
    return async () => {
        return await axios.post('/update_stake_holder/variant/project', data);
    };
}
export function deleteStakeHolder(data) {
    return async () => {
        return await axios.post('/delete_stake_holder/variant/project', data);
    };
}
export function assignEmployee(data) {
    return async () => {
        return await axios.post('/assign_employee/variant/project', data);
    };
}
export function updateAssignee(data) {
    return async () => {
        return await axios.post('/update_employee/variant/project', data);
    };
}
export function deleteAssignee(data) {
    return async () => {
        return await axios.post('/delete_employee/variant/project', data);
    };
}
export function addZone(data) {
    return async () => {
        return await axios.post('/create_zone/variant/project', data);
    };
}
export function updateZone(data) {
    return async () => {
        return await axios.post('/update_zone/variant/project', data);
    };
}
export function deleteZone(data) {
    return async () => {
        return await axios.post('/delete_zone/variant/project', data);
    };
}
export function getZone(data) {
    return async () => {
        return await axios.post('/zone_detail/variant/project', data);
    };
}
export function addProjectPlan(data) {
    return async () => {
        return await axios.post('/create_project_plan/variant/project', data);
    };
}

export function updateProjectPlan(data) {
    return async () => {
        return await axios.post('/update_project_plan/variant/project', data);
    };
}
export function deleteProjectPlan(data) {
    return async () => {
        return await axios.post('/delete_project_plan/variant/project', data);
    };
}

export function addPlanVersion(data) {
    return async () => {
        return await axios.post('/add_plan_version/variant/project', data);
    };
}
export function updatePlanVersion(data) {
    return async () => {
        return await axios.post('/update_plan_version/variant/project', data);
    };
}
export function getFloor(data) {
    return async () => {
        return await axios.post('/floor_detail/zoon/project', data);
    };
}
export function addFloor(data) {
    return async () => {
        return await axios.post('/create_floor/zoon/project', data);
    };
}

export function updateFloor(data) {
    return async () => {
        return await axios.post('/update_floor/zoon/project', data);
    };
}

export function deleteFloor(data) {
    return async () => {
        return await axios.post('/delete_floor/zoon/project', data);
    };
}
