import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ path: '/', element: <PagesLanding /> },LoginRoutes, MainRoutes]);
}
