export const FIREBASE_API = {
    apiKey: 'AIzaSyCRRuQJMlQ37wqkziBHAT-3i5ijIrSv55A',
    authDomain: 'a-task-25ed1.firebaseapp.com',
    projectId: 'a-task-25ed1',
    storageBucket: 'a-task-25ed1.appspot.com',
    messagingSenderId: '244734210110',
    appId: '1:244734210110:web:247a3f09b4f17c6ddf616f',
    measurementId: 'G-CE2N8TK5YT'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/all-tasks';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
